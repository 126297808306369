import React from "react";
import LazyImage from "../../molecules/layout/Images/LazyImage";
import Image from "@image";

const inuua_partners = [
    {
        image: "images/partners/tile_and_carpet.png",
        height: "100",
        width: "129",
        name: "Tile and Carpet",
    },
    {
        image: "images/partners/nyumba.png",
        height: "199",
        width: "145",
        name: "Nyumba",
    },
    {
        image: "images/partners/TopTank.png",
        height: "87",
        width: "251",
        name: "Top Tank",
    },
    {
        image: "images/partners/toproof.png",
        height: "100",
        width: "316",
        name: "Toproof",
    },
];

function InuuaPartners() {
    return (
        <section id="inuua-partners" className="section section-lg pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center mb-5">
                        <h2 className="mb-2">Our Partners</h2>
                        <p className="lead mb-5">
                            Some of the partners we work with on our mission
                        </p>
                        <div className="d-flex flex-column align-items-center  flex-md-row justify-content-md-between align-items-md-center">
                            {inuua_partners.map((partner, idx) => (
                                <div className="my-md-2" key={idx}>
                                    <Image
                                        src={`/${partner.image}`}
                                        height={partner.height}
                                        width={partner.width}
                                        alt={`Inuua Tujenge Partner - ${partner.name}`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 text-center pb-5"></div>
                </div>
            </div>
        </section>
    );
}

export default InuuaPartners;
